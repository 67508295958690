<script setup>
import StaffLayout from '@/Layouts/StaffLayout.vue';
import Table from '@/Components/Table/Table.vue';
import { computed } from 'vue';
import DataCard from '@/Components/DataCard.vue';
import DetailsCard from '@/Components/Location/DetailsCard.vue';
import { useI18n } from 'vue-i18n';

import LinkButton from '@/Components/Button/Link.vue';

const { t } = useI18n();
const props = defineProps({
    invoice: Object,
});

const tableSettings = [
    { label: t('Description'), width: '30%' },
    {
        label: t('Amount'),
        width: '15%',
        postFix: '&nbsp;<span class="text-2.5">ex.btw</span>',
    },
];

const tableData = computed(() =>
    props.invoice.invoice_lines.map((line) => {
        return {
            id: line.id,
            rowData: [line.description, '€ ' + line.amount],
        };
    })
);
</script>

<template>
    <StaffLayout>
        <template #default> </template>
    </StaffLayout>
</template>
 r